import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Advanced_User/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Kerberos.io video surveillance and INSTAR cameras",
  "path": "/Advanced_User/Kerberos_with_Docker/",
  "dateChanged": "2021-07-14",
  "author": "Mike Polinowski",
  "excerpt": "Kerberos is a video surveillance solution, that uses computer vision algorithms to detect changes, and that can trigger actions (notifications, api, etc).",
  "social": "/images/Search/P_SearchThumb_Lupus_XT2.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Lupus_white.webp",
  "chapter": "Advanced User",
  "category": "smarthome",
  "type": "Kerberos"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title='Kerberos.io video surveillance and INSTAR cameras' dateChanged='2021-07-14' author='Mike Polinowski' tag='INSTAR IP Camera' description='Kerberos is a video surveillance solution, that uses computer vision algorithms to detect changes, and that can trigger actions (notifications, api, etc).' image='/images/Search/P_SearchThumb_Lupus_XT2.png' twitter='/images/Search/P_SearchThumb_Lupus_XT2.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Erweitert/Kerberos_mit_Docker/' locationFR='/fr/Advanced_User/Kerberos_with_Docker/' crumbLabel="Kerberos" mdxType="BreadCrumbs" />
 
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "kerberosio-video-surveillance-and-instar-cameras",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#kerberosio-video-surveillance-and-instar-cameras",
        "aria-label": "kerberosio video surveillance and instar cameras permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Kerberos.io video surveillance and INSTAR cameras`}</h1>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#installation-mit-docker"
        }}>{`Installation mit Docker`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#download"
            }}>{`Download`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#ausf%C3%BChren-der-software"
            }}>{`Ausführen der Software`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#konfiguration-der-kamera"
            }}>{`Konfiguration der Kamera`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#bewegungserkennug"
        }}>{`Bewegungserkennug`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#alarm-benachrichtigung"
            }}>{`Alarm Benachrichtigung`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#alarmaufzeichnungen"
            }}>{`Alarmaufzeichnungen`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#videostreaming"
        }}>{`Videostreaming`}</a></li>
    </ul>
    {/* /TOC */}
    <h2 {...{
      "id": "installation-with-docker",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#installation-with-docker",
        "aria-label": "installation with docker permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Installation with Docker`}</h2>
    <p>{`Keberos offers a number of ways to install the software. The recommended way is via a Rapsberry Pi image. This just needs to be "burned" onto an SD card and the Pi then booted. For more information, please see the `}<a parentName="p" {...{
        "href": "https://doc.kerberos.io/opensource/installation/"
      }}>{`Kerberos Online Documentation`}</a>{`. I will now go the Docker route here. Docker must be `}<a parentName="p" {...{
        "href": "https://docs.docker.com/get-docker/"
      }}>{`installed on the system`}</a>{`. This path can then be used under LINUX as well as Windows and macOS.`}</p>
    <h3 {...{
      "id": "download",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#download",
        "aria-label": "download permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Download`}</h3>
    <p>{`Start by pulling the appropriate image from the Docker Hub:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`docker pull kerberos/kerberos`}</code></pre></div>
    <p>{`Now you can start up an instance (container) of this image for each of your cameras:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`docker run --name garage -p `}<span parentName="code" {...{
            "className": "token number"
          }}>{`8080`}</span>{`:80 -p `}<span parentName="code" {...{
            "className": "token number"
          }}>{`8889`}</span>{`:8889 -d kerberos/kerberos`}</code></pre></div>
    <h3 {...{
      "id": "running-the-software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#running-the-software",
        "aria-label": "running the software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Running the Software`}</h3>
    <p>{`This will start a container named `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`garage`}</code>{` (as an example - name the container so that you can associate it with a camera). That provides the web interface on port `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`8080`}</code>{` and the `}<a parentName="p" {...{
        "href": "#videostreaming"
      }}>{`MJPEG video stream of the camera`}</a>{` on port `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`8889`}</code>{`. If you want to add more cameras, start a separate container for each with a unique port:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`docker run --name frontdoor -p `}<span parentName="code" {...{
            "className": "token number"
          }}>{`8081`}</span>{`:80 -p `}<span parentName="code" {...{
            "className": "token number"
          }}>{`8890`}</span>{`:8889 -d kerberos/kerberos
docker run --name garden -p `}<span parentName="code" {...{
            "className": "token number"
          }}>{`8082`}</span>{`:80 -p `}<span parentName="code" {...{
            "className": "token number"
          }}>{`8891`}</span>{`:8889 -d kerberos/kerberos`}</code></pre></div>
    <p>{`You will then find the web interface of these cameras under the port `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`8081`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`8082`}</code>{` and the video stream under `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`8090`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`8091`}</code>{`. I.e. if you open your web browser on the same computer on which you started the containers, you can access the cameras via the following URLs:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`http://localhost:8080
http://localhost:8081
http://localhost:8082`}</code></pre></div>
    <p>{`From another computer on the same network, simply replace `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`localhost`}</code>{` with the local IP of the computer running the Docker container.`}</p>
    <p>{`Note that Docker will delete all data created by the software inside the container when you remove the container. If you want to keep the data, you need to "mount" a so-called volume in the container. This turns the command to run the container `}<strong parentName="p">{`UNDER LINUX`}</strong>{` for the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`garage`}</code>{` camera into:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`docker run --name garage `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
-p `}<span parentName="code" {...{
            "className": "token number"
          }}>{`8080`}</span>{`:80 -p `}<span parentName="code" {...{
            "className": "token number"
          }}>{`8889`}</span>{`:8889 `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
-v /opt/kerberos/config:/etc/opt/kerberos/config `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
-v /opt/kerberos/capture:/etc/opt/kerberosio/capture `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
-v /opt/kerberos/logs:/etc/opt/kerberosio/logs `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
-v /opt/kerberos/webconfig:/var/www/web/config `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
-d kerberos/kerberos`}</code></pre></div>
    <p>{`You can see that 4 volumes are assigned to the container here, which can all be found on our Linux PC under `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/opt/kerberos`}</code>{`. This folder must be created by us and the Docker user must have write access to it!`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`mkdir`}</span>{` -p /opt/kerberos/`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`config,capture,logs,webconfig`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <h3 {...{
      "id": "configuration-of-the-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#configuration-of-the-camera",
        "aria-label": "configuration of the camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Configuration of the Camera`}</h3>
    <p>{`We have now started a container that can be reached via port '8080' with our web browser. Since I started the container on the server with the IP `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`192.168.2.111`}</code>{`, I can access the Kerberos web interface via the following address:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`http://192.168.2.111:8080`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/46859833709adca622d7e501facc783b/d9199/Kerberos_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "72.60869565217392%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAB4ElEQVQoz6WTz4vUMBSA519ZwRX8B1xWZkEP3jx4FBG8e/Cm7EWvC3sYPCiisx5ERBRlVUSXEQRHdFAQRcXaYh2nTforbdNJmrZJ08h21umyO7iC3yGQvPcl78FLS/0HrR17Q9d13RgOh6b5Q9M00zS/aZqu6wXne8t5nidJ4nkeCgIInRjHGGOWMinl3rJSKmMsRKjI8zwvaEqzLP9b2YLzUgjBOS+KycoLXpalUqqsqYOCCzHJklXVyCjGMAgchBzf91HgR7EbRjnnGWOWZQEAnBrLsiAEnudxLhrZ6L/69Hj94/rD72/fAE37/OSR8aL363WfYGzZtg0AhNC2QRAgHGNKqRDb5AdnTl0+uL/bXvhw765Sqr+60pmfu9FewA6kjFFCaJpSSlm2ydTcku+fPtmZn7uytPj+9q0xtI3eRufAvuuHD41dt/zT3pSqppGfnjt782h77diRl6sreDTsLV/oLi3eOXE8RaisKilltYtGTqIwcFwfAN9xwiDwRqPQcyPf40VBKK1m0cg2sAF0cDIOo4gQQhkbE5IXRVkKQsg0dVOSsqoLaWS5bb+jPSHE7gdnTNjM8Faonk3v65fepYvPls/rG8/rfNn6l98zuTT6ab5b6w6uXR0NBpPD3zGpP9Ndq6goAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/46859833709adca622d7e501facc783b/e4706/Kerberos_01.avif 230w", "/en/static/46859833709adca622d7e501facc783b/d1af7/Kerberos_01.avif 460w", "/en/static/46859833709adca622d7e501facc783b/7f308/Kerberos_01.avif 920w", "/en/static/46859833709adca622d7e501facc783b/542bc/Kerberos_01.avif 960w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/46859833709adca622d7e501facc783b/a0b58/Kerberos_01.webp 230w", "/en/static/46859833709adca622d7e501facc783b/bc10c/Kerberos_01.webp 460w", "/en/static/46859833709adca622d7e501facc783b/966d8/Kerberos_01.webp 920w", "/en/static/46859833709adca622d7e501facc783b/e46b2/Kerberos_01.webp 960w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/46859833709adca622d7e501facc783b/81c8e/Kerberos_01.png 230w", "/en/static/46859833709adca622d7e501facc783b/08a84/Kerberos_01.png 460w", "/en/static/46859833709adca622d7e501facc783b/c0255/Kerberos_01.png 920w", "/en/static/46859833709adca622d7e501facc783b/d9199/Kerberos_01.png 960w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/46859833709adca622d7e501facc783b/c0255/Kerberos_01.png",
              "alt": "Kerberos & INSTAR IP Cameras",
              "title": "Kerberos & INSTAR IP Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`After creating a user, I can log in with this user and start configure the camera on the `}<strong parentName="p">{`Configuration`}</strong>{` tab:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/63c196614699da6b8d9376f2c24a51c1/dd104/Kerberos_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.043478260869556%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABjUlEQVQoz4VRXUsjMRTNzxNE8FXwwa+iu4pPi4jPiv4LwSfB1o8XYZ8WWRYWrWIRq06tiA7t6LQzSSbJpMlNIjNTa1/UwyHc3OTcnHuDCMYilQJj//Q/64TOOWuty6GUiuMYANwnQHe3t0++/+R5taPDJAgKcaHHMfY8T/bkp2KMMWOMUCq0tsYMnymlaJJopZxzJAzbnuecAwCTw1qLPlxZKykZeB624JzTSkkhBskijyghnHMppTVGCW6NGdB9B6QBzHulTDz08gDGGMYYpZQxNnwBPTY8QQikqaRUpyLF8etN/aV+TdutzGTeGwDEUdQJw6jbLbrNSliLWufV9sV5u1Z7Pjtt1S6v9ss742PboyO/11aLvr+yHWFCOQcALaXu9RTnSRDQoCWiqJcknUaj22x2m/fZ+vDQD3LqVKC9xZ+V5SW/Wi1M9uecB4///u5OTuzPz5Xnpg8Xfxyv/DpYKFVKM5XSTHl26uWmjk62Nv9srPvVs0wDkH1FMW1rDYDiXAnRJ2dKfGytMW9UB2TTTMYU7AAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/63c196614699da6b8d9376f2c24a51c1/e4706/Kerberos_02.avif 230w", "/en/static/63c196614699da6b8d9376f2c24a51c1/d1af7/Kerberos_02.avif 460w", "/en/static/63c196614699da6b8d9376f2c24a51c1/7f308/Kerberos_02.avif 920w", "/en/static/63c196614699da6b8d9376f2c24a51c1/50d16/Kerberos_02.avif 1064w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/63c196614699da6b8d9376f2c24a51c1/a0b58/Kerberos_02.webp 230w", "/en/static/63c196614699da6b8d9376f2c24a51c1/bc10c/Kerberos_02.webp 460w", "/en/static/63c196614699da6b8d9376f2c24a51c1/966d8/Kerberos_02.webp 920w", "/en/static/63c196614699da6b8d9376f2c24a51c1/8c558/Kerberos_02.webp 1064w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/63c196614699da6b8d9376f2c24a51c1/81c8e/Kerberos_02.png 230w", "/en/static/63c196614699da6b8d9376f2c24a51c1/08a84/Kerberos_02.png 460w", "/en/static/63c196614699da6b8d9376f2c24a51c1/c0255/Kerberos_02.png 920w", "/en/static/63c196614699da6b8d9376f2c24a51c1/dd104/Kerberos_02.png 1064w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/63c196614699da6b8d9376f2c24a51c1/c0255/Kerberos_02.png",
              "alt": "Kerberos & INSTAR IP Cameras",
              "title": "Kerberos & INSTAR IP Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Click on `}<strong parentName="p">{`IP Camera`}</strong>{` to add the access data for your INSTAR IP camera:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e625d3ead857d03ed938e23f0a3b3813/d9199/Kerberos_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.08695652173914%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACOUlEQVQoz3WSzU8TQRjG+4/Ubj8I0Ha3WG2IyMVgjAeJiR686EGjRmt2u630G6tyITHGBE/EAydv/gFGPRhuysmQRYqtpUpRamlpXXa2Ozszr9ndhvjFmzlMMvnled7nGddwKHbq9HRxdq4wO5cvPMhkS9n8/b9OJje45IsPp86cHwnF+LGJcOSEy+Pnk3cLfcNo7XbUfcQYg0PGeUrIWc4vRKKTFswFhEQyRynr9VRV3TcMw7SGEEKYPZRSQoiJDazrACDJWW9AiERPWrA3IEhyFgAQ0hBCqqpqmqbrOjZNBx7ImsTU+4fC1JKgjj3KmIkQJQQAKp823r1frpZXGrUPlu1kjjuAOT8vyhkAwBhTasG2bRNrGjUxAHxeXlx5Nv366cUXT64CUEnOeXy8cNSGPT5elNIWY+Du92+MUscrpdTsW0sqLxeXMhfm45eely4DgJjM24ENlAUpYSnrmvZVUQghln/GCMZ6rwcASwuPUufG56+dfZy4AozJqd9gjy88UDZNAGhtbiKboYQ4ytuNrTVl9eOaUl5ft5TFGbd7OBw6PjoSdXm44J14crAzY836F/RTBQBir/1vz6KYPsIFeWE8GIpZgUlOYAhhHTFKGO1TgijRGcNOz/YezIlTSua8Q5HIscnw2ITL7RmVZ4p7lY1Xheybe6Xa23SrfPuHIjZXr3frC//5YX9U5Q3dvCF2252tarVRrTW3q61mpbVTbe1Udpv1Tmfv4LTbHVVVb8VTvqGIU9UvkCYhW212I7IAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e625d3ead857d03ed938e23f0a3b3813/e4706/Kerberos_03.avif 230w", "/en/static/e625d3ead857d03ed938e23f0a3b3813/d1af7/Kerberos_03.avif 460w", "/en/static/e625d3ead857d03ed938e23f0a3b3813/7f308/Kerberos_03.avif 920w", "/en/static/e625d3ead857d03ed938e23f0a3b3813/542bc/Kerberos_03.avif 960w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e625d3ead857d03ed938e23f0a3b3813/a0b58/Kerberos_03.webp 230w", "/en/static/e625d3ead857d03ed938e23f0a3b3813/bc10c/Kerberos_03.webp 460w", "/en/static/e625d3ead857d03ed938e23f0a3b3813/966d8/Kerberos_03.webp 920w", "/en/static/e625d3ead857d03ed938e23f0a3b3813/e46b2/Kerberos_03.webp 960w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e625d3ead857d03ed938e23f0a3b3813/81c8e/Kerberos_03.png 230w", "/en/static/e625d3ead857d03ed938e23f0a3b3813/08a84/Kerberos_03.png 460w", "/en/static/e625d3ead857d03ed938e23f0a3b3813/c0255/Kerberos_03.png 920w", "/en/static/e625d3ead857d03ed938e23f0a3b3813/d9199/Kerberos_03.png 960w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e625d3ead857d03ed938e23f0a3b3813/c0255/Kerberos_03.png",
              "alt": "Kerberos & INSTAR IP Cameras",
              "title": "Kerberos & INSTAR IP Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Here you need the `}<a parentName="p" {...{
        "href": "/en/Outdoor_Cameras/IN-9008_HD/Video_Streaming/"
      }}>{`RTSP video stream of your camera`}</a>{`. At this point I take the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/11`}</code>{` stream, which provides me with the full resolution of the camera - for Full HD cameras this is the 1920x1080 stream. `}<strong parentName="p">{`Confirm`}</strong>{` the entries and don't forget to press `}<strong parentName="p">{`Update`}</strong>{` in the `}<strong parentName="p">{`Configuration`}</strong>{` menu at the bottom left to save the entries (`}<strong parentName="p">{`not at the bottom right`}</strong>{` - this `}<strong parentName="p">{`Update`}</strong>{` button only updates the snapshot above. However, as you have not saved the data at this point, it will not be displayed there).`}</p>
    <p>{`It will take a moment for Kerberos software to start providing the stream - switch to the `}<strong parentName="p">{`Dashboard`}</strong>{` tab and refresh the tab until the camera is displayed:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0353d245f744094f37bc221e3913e399/bb2fd/Kerberos_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "61.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACIklEQVQoz12R208TQRTG99/xVU2MSkxMfOJBE4P6gFKMEh5IaLDaiEYDTQFDSW9ZLeWm9mLaAA0oIQRiRFEh8oDxpYkJELbaNGV2290pa3dnzozpbqvW72kyZ37n+84ZIZfLfd3Z2dvb1bBaqWBd11W1nM///JGTZBlls1npQCoUCowxAEpMg3POGOOWhGQq7R15EolOLCwtR6eiIXEsGPb5gz7P0EAyGWOMEUI4518OPnZlrorXW7cCIcYtmDHB7w8urnxYXdvIvFlPzc3f63cNjw55Ht9x9TjGI6LtcFgpXH5+Ttz0fX+7Jp48/m02zTkHSoVwOPQ0Ojm7sJpZ3pyJJ9tvdva6nLc62k6fOOYZfGTDn/bfXYtdqFQ1zvnKw/6lPmcNJkTwB31nW06577unE6+9vkCX41J350XXA3d3b493eBAYcM4LWr7txfnJ7dDuxvvxljPbL2co5wAgZBbnOhztgYA3noxFpydGB5zhkbuv0olEKv4sIrLafLUJP++v356/It5o3RoLaEdlJB8aVUMolRSEUNVoWqN9YIxJkoQQKhaLpkkoUKOqN20bY1wulw0LBkusIQDAuIIxVlXVMA0AMAkBShlAHaaUNh5zG/wHrzsAA0KIfUMptT+vDttuYJX/C98kAKC0KXa9JbAjRWZ/a01dTNOs49YD0zTBSi780nWsyFiWcamkIYRLiiYjrMiaIqsyoo2EfwC7ke38G0oUYpzy5qrPAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0353d245f744094f37bc221e3913e399/e4706/Kerberos_04.avif 230w", "/en/static/0353d245f744094f37bc221e3913e399/d1af7/Kerberos_04.avif 460w", "/en/static/0353d245f744094f37bc221e3913e399/7f308/Kerberos_04.avif 920w", "/en/static/0353d245f744094f37bc221e3913e399/9f9f7/Kerberos_04.avif 1058w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0353d245f744094f37bc221e3913e399/a0b58/Kerberos_04.webp 230w", "/en/static/0353d245f744094f37bc221e3913e399/bc10c/Kerberos_04.webp 460w", "/en/static/0353d245f744094f37bc221e3913e399/966d8/Kerberos_04.webp 920w", "/en/static/0353d245f744094f37bc221e3913e399/acaf1/Kerberos_04.webp 1058w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0353d245f744094f37bc221e3913e399/81c8e/Kerberos_04.png 230w", "/en/static/0353d245f744094f37bc221e3913e399/08a84/Kerberos_04.png 460w", "/en/static/0353d245f744094f37bc221e3913e399/c0255/Kerberos_04.png 920w", "/en/static/0353d245f744094f37bc221e3913e399/bb2fd/Kerberos_04.png 1058w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0353d245f744094f37bc221e3913e399/c0255/Kerberos_04.png",
              "alt": "Kerberos & INSTAR IP Cameras",
              "title": "Kerberos & INSTAR IP Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "motion-detection",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#motion-detection",
        "aria-label": "motion detection permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Motion Detection`}</h2>
    <p>{`Back on the `}<strong parentName="p">{`Configuration`}</strong>{` tab, we can now press the `}<strong parentName="p">{`Motion`}</strong>{` button under `}<strong parentName="p">{`Surveillance`}</strong>{` to set the alarm actions:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/63c196614699da6b8d9376f2c24a51c1/dd104/Kerberos_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.043478260869556%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABjUlEQVQoz4VRXUsjMRTNzxNE8FXwwa+iu4pPi4jPiv4LwSfB1o8XYZ8WWRYWrWIRq06tiA7t6LQzSSbJpMlNIjNTa1/UwyHc3OTcnHuDCMYilQJj//Q/64TOOWuty6GUiuMYANwnQHe3t0++/+R5taPDJAgKcaHHMfY8T/bkp2KMMWOMUCq0tsYMnymlaJJopZxzJAzbnuecAwCTw1qLPlxZKykZeB624JzTSkkhBskijyghnHMppTVGCW6NGdB9B6QBzHulTDz08gDGGMYYpZQxNnwBPTY8QQikqaRUpyLF8etN/aV+TdutzGTeGwDEUdQJw6jbLbrNSliLWufV9sV5u1Z7Pjtt1S6v9ss742PboyO/11aLvr+yHWFCOQcALaXu9RTnSRDQoCWiqJcknUaj22x2m/fZ+vDQD3LqVKC9xZ+V5SW/Wi1M9uecB4///u5OTuzPz5Xnpg8Xfxyv/DpYKFVKM5XSTHl26uWmjk62Nv9srPvVs0wDkH1FMW1rDYDiXAnRJ2dKfGytMW9UB2TTTMYU7AAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/63c196614699da6b8d9376f2c24a51c1/e4706/Kerberos_02.avif 230w", "/en/static/63c196614699da6b8d9376f2c24a51c1/d1af7/Kerberos_02.avif 460w", "/en/static/63c196614699da6b8d9376f2c24a51c1/7f308/Kerberos_02.avif 920w", "/en/static/63c196614699da6b8d9376f2c24a51c1/50d16/Kerberos_02.avif 1064w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/63c196614699da6b8d9376f2c24a51c1/a0b58/Kerberos_02.webp 230w", "/en/static/63c196614699da6b8d9376f2c24a51c1/bc10c/Kerberos_02.webp 460w", "/en/static/63c196614699da6b8d9376f2c24a51c1/966d8/Kerberos_02.webp 920w", "/en/static/63c196614699da6b8d9376f2c24a51c1/8c558/Kerberos_02.webp 1064w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/63c196614699da6b8d9376f2c24a51c1/81c8e/Kerberos_02.png 230w", "/en/static/63c196614699da6b8d9376f2c24a51c1/08a84/Kerberos_02.png 460w", "/en/static/63c196614699da6b8d9376f2c24a51c1/c0255/Kerberos_02.png 920w", "/en/static/63c196614699da6b8d9376f2c24a51c1/dd104/Kerberos_02.png 1064w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/63c196614699da6b8d9376f2c24a51c1/c0255/Kerberos_02.png",
              "alt": "Kerberos & INSTAR IP Cameras",
              "title": "Kerberos & INSTAR IP Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`First you can draw an area in the camera snapshot in which the motion detection should operate:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c20fe58e56dac4f7801fde8e2e481de8/d9199/Kerberos_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "48.69565217391305%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACNElEQVQoz2PQ0jUzs3QsLa9taGqvqWuprG6sqgEjMKOyurGmthmE6kBkfVNHfmG5jp6Fjj4IMahpGnv6hHz+/OXHr9+/fv/5jxv8/ffv//9/jx4+1jGw0gbpt2RQ1TBy9wx6+fLVuo2bV69auXbNyt17d+3as2v7zq379u7cum3jmnUr16xdsWLlkrnzZ+/bs+vhw8c6+jDNKhpGnt4hT58+raip6uifUNfcOmvB4rVbdk6dPbumrry6vry8sqiiurisoiA3P2POvDlPnjzT0beE22zs5Rv29OmzwsL8Ndv23378cur0+TsOnt6+70BnT0fPxL7Ovs6W1pqG2uLyktyly5c+efxUWxekE6H5+fPnuTmZHT2Tnr3+WFPfvmHn0W17dqdnZyVnZjS2NZVnR2YE2wY46U+c1Pfy5WstHVNNHVNNXTOE5vLKkqLS/G07d9e3Td5/+s7MRctSszLi09Mj48KjYsIMjAw0tNQ6O5ufv3gpr26kqmGopmnEoAoO7ddv3lRUlZhZGFdXlTa39K7cfmLaghU1DdWlWcGZaWHlddUFZaVltVU9/e0vnzx11zHW0DBS0zQGhbaHV9Drt2+LSnNl5STDI3wmdNb2T5hQWF4SHuZXGGWVHuPS2tU8YfqEkqqS4qryb8+eTrK21NMwVAbZDIqqwHfv3q9csyy/IHfS5O7FC6cePLD/4MH927etXzKzY+aEhiXLl+w+sH/h0kWTp0/9/PRprYmJjrq+ioYhADseNahvBYyiAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c20fe58e56dac4f7801fde8e2e481de8/e4706/Kerberos_05.avif 230w", "/en/static/c20fe58e56dac4f7801fde8e2e481de8/d1af7/Kerberos_05.avif 460w", "/en/static/c20fe58e56dac4f7801fde8e2e481de8/7f308/Kerberos_05.avif 920w", "/en/static/c20fe58e56dac4f7801fde8e2e481de8/542bc/Kerberos_05.avif 960w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c20fe58e56dac4f7801fde8e2e481de8/a0b58/Kerberos_05.webp 230w", "/en/static/c20fe58e56dac4f7801fde8e2e481de8/bc10c/Kerberos_05.webp 460w", "/en/static/c20fe58e56dac4f7801fde8e2e481de8/966d8/Kerberos_05.webp 920w", "/en/static/c20fe58e56dac4f7801fde8e2e481de8/e46b2/Kerberos_05.webp 960w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c20fe58e56dac4f7801fde8e2e481de8/81c8e/Kerberos_05.png 230w", "/en/static/c20fe58e56dac4f7801fde8e2e481de8/08a84/Kerberos_05.png 460w", "/en/static/c20fe58e56dac4f7801fde8e2e481de8/c0255/Kerberos_05.png 920w", "/en/static/c20fe58e56dac4f7801fde8e2e481de8/d9199/Kerberos_05.png 960w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c20fe58e56dac4f7801fde8e2e481de8/c0255/Kerberos_05.png",
              "alt": "Kerberos & INSTAR IP Cameras",
              "title": "Kerberos & INSTAR IP Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Set the sensitivity and threshold of the detection here:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/dbca8a53e65f6ebc0aeeee4b8498b69f/87a80/Kerberos_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50.86956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABcklEQVQoz42QS07CUBSGuxBfqKUthb5Agda4EDfgFpzpyIGLUEAETFwF5RES0YEtRWeQoAGNrSFaaO/tMS0QkETjnzM4d/Dl++8hYoK8SfLHJ6fN5n1ZraqV2nTU6sKzrlbq5XKt0bjNF645UWa5ZJRPEbykrKxHCqUbALA+hvAznp/pjjAGAKP9JCb2WS4VE2SCE334/OISAL5sexwEYTw0zddu10VoPB47QWzbBsd50Fq8tDeHVzfYTLYAALZtI4Qmrk/Leut0fDnGgd9DrgsAmm7wojKH10JsJnsFABjAfHk2e70JM6vtLcK63l6Co5mcb3Zc1xoMzH4feZ7ffjRCCOFZXNcFD2t6O8Ilw4xEswkfDoXYXGBGCMHvwUEXXWtx0R2SligmToQF5YCK3BV9Mw4+PL/v0uUDuG8YR+k0TYnbtESQgnJIM49F3+z9aZ7A723jTJEZit+iJSImKnQoUsrm/1nb0Fq7bJwMc2Em/g0QHr/7G5raLgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/dbca8a53e65f6ebc0aeeee4b8498b69f/e4706/Kerberos_06.avif 230w", "/en/static/dbca8a53e65f6ebc0aeeee4b8498b69f/d1af7/Kerberos_06.avif 460w", "/en/static/dbca8a53e65f6ebc0aeeee4b8498b69f/7f308/Kerberos_06.avif 920w", "/en/static/dbca8a53e65f6ebc0aeeee4b8498b69f/98c62/Kerberos_06.avif 973w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/dbca8a53e65f6ebc0aeeee4b8498b69f/a0b58/Kerberos_06.webp 230w", "/en/static/dbca8a53e65f6ebc0aeeee4b8498b69f/bc10c/Kerberos_06.webp 460w", "/en/static/dbca8a53e65f6ebc0aeeee4b8498b69f/966d8/Kerberos_06.webp 920w", "/en/static/dbca8a53e65f6ebc0aeeee4b8498b69f/0ea8a/Kerberos_06.webp 973w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/dbca8a53e65f6ebc0aeeee4b8498b69f/81c8e/Kerberos_06.png 230w", "/en/static/dbca8a53e65f6ebc0aeeee4b8498b69f/08a84/Kerberos_06.png 460w", "/en/static/dbca8a53e65f6ebc0aeeee4b8498b69f/c0255/Kerberos_06.png 920w", "/en/static/dbca8a53e65f6ebc0aeeee4b8498b69f/87a80/Kerberos_06.png 973w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/dbca8a53e65f6ebc0aeeee4b8498b69f/c0255/Kerberos_06.png",
              "alt": "Kerberos & INSTAR IP Cameras",
              "title": "Kerberos & INSTAR IP Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`In the next step, you can specify whether you only want snapshots or also alarm videos, whether the software should "burn" a time stamp into the video image and how long the camera should record in the event of an alarm:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d9f0f5b02f706d07707a3c981dda1271/0b6f4/Kerberos_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABm0lEQVQoz12Ry07CQBiFeQqJCsUaodBWG0CNBp/IB3BtXMlCFy515VKFlpuJT4CWojERXJhIXOjCC16otNPO5TdtQYWTfzGL+eac809ITGWjkUQ+v9tq3dZ1o3l13TQujcalrhu6bjT0pjcNb87rF+2b9s7OHhcRJHExKaRDC+kcx8sFtQwAzw+PgCn4IpRR5okywJQSSm3kAECpfBrjJSWTk5UVD47GxIJWI72Pw431073t1+57z/z2sFG5rgsAqlaNzcpKZk1WVkNKOjfNpdRi1YL+5tnW/sVBr/fd79uBP2MMhiKEAIBWqnH8EPacZ6TjEw0Avt4/zS/TxXhw23Ec0xyDVbXCzUh/MMfLAWwj5LoOISTIyQDMtzfHsoIIrt9ZLVU5fhwuAQBCaID5hb0V3t+b3S4AUEqR/0pRq05GEuL8siBmR5wxxi+dDsGY+VZBYTY8Obbtw5XwdDwlLydS2ZHOlmU/3d0h28YYBxF+K1DGBrBamZiaE6SlePDPkZh45MMIOdiy/m/4vzD2FlYolsNTc4KYjSczPyNks5qs9679AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d9f0f5b02f706d07707a3c981dda1271/e4706/Kerberos_07.avif 230w", "/en/static/d9f0f5b02f706d07707a3c981dda1271/d1af7/Kerberos_07.avif 460w", "/en/static/d9f0f5b02f706d07707a3c981dda1271/7f308/Kerberos_07.avif 920w", "/en/static/d9f0f5b02f706d07707a3c981dda1271/be2d7/Kerberos_07.avif 984w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d9f0f5b02f706d07707a3c981dda1271/a0b58/Kerberos_07.webp 230w", "/en/static/d9f0f5b02f706d07707a3c981dda1271/bc10c/Kerberos_07.webp 460w", "/en/static/d9f0f5b02f706d07707a3c981dda1271/966d8/Kerberos_07.webp 920w", "/en/static/d9f0f5b02f706d07707a3c981dda1271/d4a71/Kerberos_07.webp 984w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d9f0f5b02f706d07707a3c981dda1271/81c8e/Kerberos_07.png 230w", "/en/static/d9f0f5b02f706d07707a3c981dda1271/08a84/Kerberos_07.png 460w", "/en/static/d9f0f5b02f706d07707a3c981dda1271/c0255/Kerberos_07.png 920w", "/en/static/d9f0f5b02f706d07707a3c981dda1271/0b6f4/Kerberos_07.png 984w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d9f0f5b02f706d07707a3c981dda1271/c0255/Kerberos_07.png",
              "alt": "Kerberos & INSTAR IP Cameras",
              "title": "Kerberos & INSTAR IP Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "alarm-notifications",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#alarm-notifications",
        "aria-label": "alarm notifications permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Alarm Notifications`}</h3>
    <p>{`Kerberos offers you 4 optional notifications:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`HTTP Alarmserver (Webhook)`}</strong>{`: Contact an HTTP address in your automation software to trigger further actions there. Here we have an example of how you can integrate the `}<a parentName="li" {...{
          "href": "/en/Advanced_User/Alarmserver_Queries_for_your_Smarthome/"
        }}>{`INSTAR HTTP alarm server of your camera into Node-RED`}</a>{` - this will work the same way with the Kerberos webhook.`}</li>
      <li parentName="ul"><strong parentName="li">{`Script`}</strong>{`: Run any shell script on your Linux system (the Linux system here is the one that is running inside the Docker container!).`}</li>
      <li parentName="ul"><strong parentName="li">{`GPIO`}</strong>{`: If you have installed Kerberos on a Rapsberry Pi, you can let the software directly address the pins on the mainboard and thus operate a device that is connected to the Pi via these pins.`}</li>
      <li parentName="ul"><strong parentName="li">{`MQTT`}</strong>{`: For example, enter the `}<a parentName="li" {...{
          "href": "/en/Web_User_Interface/1080p_Series/Network/MQTT/"
        }}>{`INSTAR MQTT Broker of your camera here`}</a>{`. If you have integrated your `}<a parentName="li" {...{
          "href": "/en/Advanced_User/INSTAR_MQTT_Broker/"
        }}>{`camera into an automation system via MQTT`}</a>{`, you can add another MQTT topic with which you can react to events.`}</li>
    </ul>
    <p><strong parentName="p">{`Example: Alarm notification via MQTT:`}</strong></p>
    <p>{`If you select MQTT as the notification form, you will see the following menu:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/761013c2e59031a716c4fcb399871616/eb4a1/Kerberos_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "86.08695652173914%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAIAAABSJhvpAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAB60lEQVQ4y4WTy27TQBSG8xZQ2bk1JLE9jl3HitqQ7nkjgtM2aagQEuINkBA4lyZPUKlPAJuKTYEtC25lUZXanvGMxwfZTkiTWM2vWY3mm/+cM/9kFNksKY0nxcqHly8IcPujfXZ5hjFhQQDLCuId2x6LYkVVG7JsZmp6Exkts4Te9o4BIGSceyQMQ1hTAg9H00JJ1estVW9mFG1PM1pCAT09eA4A2Cf4780muDaDUQzniqhz0AcA13UppUtQfJHvOJT4ADAYTfNrsNo5jJx93+ech2vilDLGIng4yW/X9Pr+CnwCAISQud9CM2c/craHE7Egq/qupDZWYc/zCCGU0nCZphhTP2rn/eB0S6zKaqMi12dwtoiSsjfD9vihUJZQo1zdmcMFZHWigTmOwzlPGZjrJmW/s8cPhHJFrj+SjJSBhWkKKGWUxc6nW2JZQmYVzXvO5hXLOk6eKgW942zbY0EoK4opVY0F/KzdjUKCcSpMPY/FPQ+Gk1wRaTtNVNtdDMw67N8HY8zi8KQkLJuX2+0jAIiSEB2+s+Kk/n/n++LJgmAJDWdp4ZwnCUt1ViyrBwDXP3/d/PgeAubslge3nOPNH0PMSd2TV38+XZz3+heD11dfj66+dH9/bl9/e7MCD0bT3LaqGY+RtvcPlEjEWhMJIj4AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/761013c2e59031a716c4fcb399871616/e4706/Kerberos_08.avif 230w", "/en/static/761013c2e59031a716c4fcb399871616/d1af7/Kerberos_08.avif 460w", "/en/static/761013c2e59031a716c4fcb399871616/7f308/Kerberos_08.avif 920w", "/en/static/761013c2e59031a716c4fcb399871616/a4458/Kerberos_08.avif 1083w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/761013c2e59031a716c4fcb399871616/a0b58/Kerberos_08.webp 230w", "/en/static/761013c2e59031a716c4fcb399871616/bc10c/Kerberos_08.webp 460w", "/en/static/761013c2e59031a716c4fcb399871616/966d8/Kerberos_08.webp 920w", "/en/static/761013c2e59031a716c4fcb399871616/b671e/Kerberos_08.webp 1083w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/761013c2e59031a716c4fcb399871616/81c8e/Kerberos_08.png 230w", "/en/static/761013c2e59031a716c4fcb399871616/08a84/Kerberos_08.png 460w", "/en/static/761013c2e59031a716c4fcb399871616/c0255/Kerberos_08.png 920w", "/en/static/761013c2e59031a716c4fcb399871616/eb4a1/Kerberos_08.png 1083w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/761013c2e59031a716c4fcb399871616/c0255/Kerberos_08.png",
              "alt": "Kerberos & INSTAR IP Cameras",
              "title": "Kerberos & INSTAR IP Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Enter the access data for your MQTT broker here. The easiest way to do this is to use the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Network/MQTT/"
      }}>{`INSTAR MQTT Broker`}</a>{` directly:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Server`}</strong>{`: IP address of your camera.`}</li>
      <li parentName="ul"><strong parentName="li">{`Port`}</strong>{`: Port of the MQTT broker, by default this is the TCP port `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`1883`}</code>{` (or `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`8883`}</code>{` if you have activated encryption).`}</li>
      <li parentName="ul"><strong parentName="li">{`Topic`}</strong>{`: Enter an MQTT topic here that Kerberos should update in the event of an alarm - for example `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`cameras/garage/kerberos`}</code>{` (it is best not to use special characters).`}</li>
      <li parentName="ul"><strong parentName="li">{`Client ID`}</strong>{`: Kerberos must log on to your MQTT broker with a client name (it is best not to use special characters).`}</li>
      <li parentName="ul"><strong parentName="li">{`Username`}</strong>{`: The user name with which Kerberos can log on to your MQTT broker must be entered here.`}</li>
      <li parentName="ul"><strong parentName="li">{`Password`}</strong>{`: The user password with which Kerberos can log in to your MQTT broker must be entered here.`}</li>
      <li parentName="ul"><strong parentName="li">{`Throttle`}</strong>{`: To prevent a continuous alarm (e.g. if there is movement in front of the camera for a long time), you can define a cool-down in seconds between triggers here.`}</li>
    </ul>
    <p><strong parentName="p">{`Confirm`}</strong>{` the entries `}<strong parentName="p">{`AND DON'T FORGET`}</strong>{` to also click on `}<strong parentName="p">{`Update`}</strong>{` at the bottom left of the `}<strong parentName="p">{`Configuration`}</strong>{` tab to save the entries.`}</p>
    <p>{`Every time an alarm is triggered, I now also see a message from Kerberos in the `}<a parentName="p" {...{
        "href": "/en/Erweitert/INSTAR_MQTT_Broker/Windows_macOS_und_LINUX_Apps/"
      }}>{`MQTT Explorer`}</a>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0f822ce25d935fe337e353ef479bce2e/54bf4/Kerberos_09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "63.04347826086957%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACNklEQVQoz4XNW0/TYBwG8H4KrzQITJHRE2u39n17eA/t2rXrxrYyus0hBxHG5hRQgZB44Y1+LolEcYtfgniJiYruwI0Z84J4IU9++V89T/7M17Oz8/Nvv3v9wWCk3x9cT6/XHw4Hl5fDseFw1Oj1et9//Ly4+MXU2x+29k9bR9213U+brzrPX3/ZPuxu7XcaB52NF59bR91a8yTcOA433i8+Oa42TxqHnXr74+G77u6bUyZ8+BY5Oxptqua2ghqQjK5ijqSMhooaKn6KMi9p9kCzd3V7x8rumc6em9/Ll4+YUtFXUmIqKaiKOBefZudiPHtvTODus/EYBIlS3o3Kedc2LAwIBpQAZIJiwWeWokoUVX0/yGR8hIgkp+bFxPy8NCaKCQA1R8dERy6yXJMiQjGhJsILCwWmVo3q9WoQeIWFnJdxOHaW5+LcFZ6b49hZRUkSShBGmq7rhj4OhDAIAqa6/Gyp2i6Em4uVlp9fxVaZpCOajog9gu3IRF5YKuaCHCHEotSyKKWEUhqGISPJICEDOQkkGSRT2j8kGeo6KoWh43qE2pTahFqen320sprNZhlB4K/h/uKvCBzPsRoEDjHSGFJDIbqCYNIy1JxnWZQw4n8jCAKEQCczbvggqLDZkpT2OA3fAeaEpoObxxpQa/xsk8htmmohZT0plKduV+KxNMY3jXkBaqpRi9lbk2R90q5P0OW7YvEWqEy5vn3zZwCBs+JlHjuZNT+95sIlbTo3IzmcZeE/v+P4miItjxUAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0f822ce25d935fe337e353ef479bce2e/e4706/Kerberos_09.avif 230w", "/en/static/0f822ce25d935fe337e353ef479bce2e/d1af7/Kerberos_09.avif 460w", "/en/static/0f822ce25d935fe337e353ef479bce2e/7f308/Kerberos_09.avif 920w", "/en/static/0f822ce25d935fe337e353ef479bce2e/651ce/Kerberos_09.avif 1007w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0f822ce25d935fe337e353ef479bce2e/a0b58/Kerberos_09.webp 230w", "/en/static/0f822ce25d935fe337e353ef479bce2e/bc10c/Kerberos_09.webp 460w", "/en/static/0f822ce25d935fe337e353ef479bce2e/966d8/Kerberos_09.webp 920w", "/en/static/0f822ce25d935fe337e353ef479bce2e/aa5f8/Kerberos_09.webp 1007w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0f822ce25d935fe337e353ef479bce2e/81c8e/Kerberos_09.png 230w", "/en/static/0f822ce25d935fe337e353ef479bce2e/08a84/Kerberos_09.png 460w", "/en/static/0f822ce25d935fe337e353ef479bce2e/c0255/Kerberos_09.png 920w", "/en/static/0f822ce25d935fe337e353ef479bce2e/54bf4/Kerberos_09.png 1007w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0f822ce25d935fe337e353ef479bce2e/c0255/Kerberos_09.png",
              "alt": "Kerberos & INSTAR IP Cameras",
              "title": "Kerberos & INSTAR IP Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`With the following information:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"regionCoordinates"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`
        `}<span parentName="code" {...{
            "className": "token number"
          }}>{`537`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token number"
          }}>{`472`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token number"
          }}>{`575`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token number"
          }}>{`583`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"numberOfChanges"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`69`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"pathToVideo"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"1626245349_6-879541_Garage_537-472-575-583_69_215.mp4"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Garage"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"timestamp"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"1626245349"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"microseconds"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"6-889345"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"token"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`215`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <h3 {...{
      "id": "alarm-recordings",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#alarm-recordings",
        "aria-label": "alarm recordings permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Alarm Recordings`}</h3>
    <p>{`You can view the video recordings of your camera under the `}<strong parentName="p">{`Dashboard`}</strong>{` tab after you have selected a date in the input field at the top left:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9c6927d5ee7da615fe7e2162259f12cd/d9199/Kerberos_10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "60.434782608695656%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACVElEQVQoz22Q7U8SARzH+ffqRWu5VKQ5E2TIHchziKjhCJmKCVxqHE93HI+C3fmcEx9Sc5RbI6GJpi7yBF+YmBeknHel9UKKz9vfPvvt++Es9prmOrRTChkhASakIA6KCVBMSABCAuCgONzSHANEhEpOKNpmNKpptXJWq5lUyOLGnreQjXNEkvl8niRJiqLY/yiVSmffTr8XCpeX5X9ODMNwstnsl2x2f3///PycZdnlleX44kJiM5FIrC2vLMQXF5bmZ+Px+XfvN5KpD1upzbX1+Nzrqe3MJ5ZlOaUbisUiy7JnZwX7MIREcCuMulGHydylkfH1OqXbh0QnpmGP0x92WyzdUlE9/ip0Lf+4gaKoq6urn6XSs15Dn3XEMzbXaegUg831tfeUcrHFNmixQoM2e4ehXQw+rnlwB/N7K+Ry+XqVsJXPbbhvgz1qOV8F1hkHnvcN9PGa6oRCnmlwSCN71KkVGvqHAqFAFXlndyedTh6SXw/2tj9ntj5upVLp9PrGWjK5mcsf7e2mD/Yy2cOjXC5XRWYYhmWYW7WLp8fHhZMTmqZvl/5b+/bmi4sLLOBzIx4vhkxOhRF0BHbaMdSFBXzBSGh8MrbyZiYyhkDQwOrqUsXnP7Wh0RHYP2596R52Dht6tBJBjVYtCURjDiz2wuV1og6zWS/g3Y1GsAqZpukyXZYpQZG0bdQXU6hAAf9hQ1Oj+olSqgDU+m7IE5QrRAIBt5bH9SKuKpu1eg0ANMKI12xUm5+CsC/ocDlbWpt0OgkaCpq6gKH+dhgL4QT+W/4FHxQTF6j67/EAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9c6927d5ee7da615fe7e2162259f12cd/e4706/Kerberos_10.avif 230w", "/en/static/9c6927d5ee7da615fe7e2162259f12cd/d1af7/Kerberos_10.avif 460w", "/en/static/9c6927d5ee7da615fe7e2162259f12cd/7f308/Kerberos_10.avif 920w", "/en/static/9c6927d5ee7da615fe7e2162259f12cd/542bc/Kerberos_10.avif 960w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9c6927d5ee7da615fe7e2162259f12cd/a0b58/Kerberos_10.webp 230w", "/en/static/9c6927d5ee7da615fe7e2162259f12cd/bc10c/Kerberos_10.webp 460w", "/en/static/9c6927d5ee7da615fe7e2162259f12cd/966d8/Kerberos_10.webp 920w", "/en/static/9c6927d5ee7da615fe7e2162259f12cd/e46b2/Kerberos_10.webp 960w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9c6927d5ee7da615fe7e2162259f12cd/81c8e/Kerberos_10.png 230w", "/en/static/9c6927d5ee7da615fe7e2162259f12cd/08a84/Kerberos_10.png 460w", "/en/static/9c6927d5ee7da615fe7e2162259f12cd/c0255/Kerberos_10.png 920w", "/en/static/9c6927d5ee7da615fe7e2162259f12cd/d9199/Kerberos_10.png 960w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9c6927d5ee7da615fe7e2162259f12cd/c0255/Kerberos_10.png",
              "alt": "Kerberos & INSTAR IP Cameras",
              "title": "Kerberos & INSTAR IP Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`If you switch to the `}<strong parentName="p">{`System`}</strong>{` tab, you can download the alarm recordings of your camera and delete them from the server:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/cabd2f96d26d99185c582516641a4c27/25c1c/Kerberos_11.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.17391304347826%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABoElEQVQoz2VQXWtTQRC9/9c2BtuUtIhIKRWpog8iPlQQ9A9o09BCsaWW1kih+OyDpLlJbpLdzd79/pjdlZs0kdLDPAzMnHNmTtaq11r12sHj1a+rK/dq5dG3eu3k2dPjzebxZrO9sX7YWDvaarY3GofrT85f712+f5dRwRGl2tr0ADHGEZrgsmRSQAgPF7JiMLjtdsejQgkhOReMWa1jCAEAvO/3ev08Hw4GU0Ik55wxybgzJoYQATJK6XA4HBUFpdQao2cICx+McVmW8ysAIKXELPfB3Tkbrb33zjkhxIyolNIAIcaYUtJaO1etAkAlGsN1cY0UquRSzJSS1lUgZKq1Xn47JyM0wRgvnWNKpS3d0lkqyRjzzkkpvff/0wJIAFNCxqMigK8icM47VzkupDNCSJ7nFR/AWuucNcZoY+ZjF4KfNXOE+3dlUlYpEkIQQhghjPFkPCaM8VZbfdjv39z8vfjRvbrs/ep0f179OTu97XTiIs5Ma22tvSPPgBAS1siPn3hj6/eXzyfbz093d85f7Z29fPF9Z/vi7Zuw+O4fCTIdTS+AajsAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cabd2f96d26d99185c582516641a4c27/e4706/Kerberos_11.avif 230w", "/en/static/cabd2f96d26d99185c582516641a4c27/d1af7/Kerberos_11.avif 460w", "/en/static/cabd2f96d26d99185c582516641a4c27/7f308/Kerberos_11.avif 920w", "/en/static/cabd2f96d26d99185c582516641a4c27/493b5/Kerberos_11.avif 1047w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/cabd2f96d26d99185c582516641a4c27/a0b58/Kerberos_11.webp 230w", "/en/static/cabd2f96d26d99185c582516641a4c27/bc10c/Kerberos_11.webp 460w", "/en/static/cabd2f96d26d99185c582516641a4c27/966d8/Kerberos_11.webp 920w", "/en/static/cabd2f96d26d99185c582516641a4c27/5c912/Kerberos_11.webp 1047w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cabd2f96d26d99185c582516641a4c27/81c8e/Kerberos_11.png 230w", "/en/static/cabd2f96d26d99185c582516641a4c27/08a84/Kerberos_11.png 460w", "/en/static/cabd2f96d26d99185c582516641a4c27/c0255/Kerberos_11.png 920w", "/en/static/cabd2f96d26d99185c582516641a4c27/25c1c/Kerberos_11.png 1047w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/cabd2f96d26d99185c582516641a4c27/c0255/Kerberos_11.png",
              "alt": "Kerberos & INSTAR IP Cameras",
              "title": "Kerberos & INSTAR IP Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "videostreaming",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#videostreaming",
        "aria-label": "videostreaming permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Videostreaming`}</h2>
    <p>{`Kerberos provides the video stream of your camera in MJPEG format. This has an advantage because it allows you to circumvent the limit of a maximum of three simultaneous accesses to the camera. So we can let Kerberos access the camera directly and then serve the stream from Kerberos to a large number of clients - the camera will only see one access at a time, regardless of the number of clients.`}</p>
    <p>{`You can test this, for example, with the `}<a parentName="p" {...{
        "href": "/en/Software/Windows/VLC_Player/"
      }}>{`VLC Player`}</a>{`. Open VLC Player and select to play a network stream:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "874px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/775f43abf928a2d27c42af57e7dda67a/30c92/Kerberos_VLC_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "57.391304347826086%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABmUlEQVQoz4XQMU/bQBgG4M9nR03EUpvIQhVbIUBr2oWB/oWqO2EFJRJiJr+hY/9Kt0qhCkMQMSJWpeDkcsbhYuxGiX12HCLZbRd0QepQEvrohtNJ732fXri2XefOC4KAsZAx5t65jLFKpbK3VyyVSofzHBwclsvlYnEfvL6NTdN1+pNxNJ2Mac8ORsNCoQAACCFYTEAITg3r64X1veWd30QX9P6b0dfp/Zb2HgBESUIISfOIopjN5qBt9a5+tC+NVgv36CC4JtTzY03TAODD29W1VwofIghPJ2cyGfjpeRbGDqVh4I8j1r/tTeLxu20e/nL88ejTDl8BLQj7vj8ajuI4nk6nSZKEYfjn96/XG9qGDJ3Pu7WTzZfZ+bN5uNFo6Lp+NdPutA3DwLizXtjMSbC9gt6soKWcyMv5hyC8yPJfeasir4C//bfkv0RRAlVV8/m8oijyzONFmVmenXlkWZFVVQVd1+v1erPZJIR0u12MMSHEelaXkIE7qNXOwDTNxwCl1HEc27ajKErTNFkgTRM2HFTdalU/fQBVwAgtd/C5+AAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/775f43abf928a2d27c42af57e7dda67a/e4706/Kerberos_VLC_01.avif 230w", "/en/static/775f43abf928a2d27c42af57e7dda67a/d1af7/Kerberos_VLC_01.avif 460w", "/en/static/775f43abf928a2d27c42af57e7dda67a/43f51/Kerberos_VLC_01.avif 874w"],
              "sizes": "(max-width: 874px) 100vw, 874px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/775f43abf928a2d27c42af57e7dda67a/a0b58/Kerberos_VLC_01.webp 230w", "/en/static/775f43abf928a2d27c42af57e7dda67a/bc10c/Kerberos_VLC_01.webp 460w", "/en/static/775f43abf928a2d27c42af57e7dda67a/02c22/Kerberos_VLC_01.webp 874w"],
              "sizes": "(max-width: 874px) 100vw, 874px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/775f43abf928a2d27c42af57e7dda67a/81c8e/Kerberos_VLC_01.png 230w", "/en/static/775f43abf928a2d27c42af57e7dda67a/08a84/Kerberos_VLC_01.png 460w", "/en/static/775f43abf928a2d27c42af57e7dda67a/30c92/Kerberos_VLC_01.png 874w"],
              "sizes": "(max-width: 874px) 100vw, 874px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/775f43abf928a2d27c42af57e7dda67a/30c92/Kerberos_VLC_01.png",
              "alt": "Kerberos & INSTAR IP Cameras",
              "title": "Kerberos & INSTAR IP Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The MJPEG stream is output via `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`HTTP`}</code>{` and we had set the streaming port `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`8889`}</code>{` for the camera `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`garage`}</code>{` above. If the IP address of the server (running Kerberos) is `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`192.168.2.111`}</code>{` and you have chosen `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`admin`}</code>{`/ `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`instar`}</code>{` as the Kerberos login, the `}<strong parentName="p">{`Streaming URL`}</strong>{` would be:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`http://admin:instar@192.168.2.111:8889`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "870px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/01d59eb54ac16322c3814abf4a9d9148/3f3b9/Kerberos_VLC_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "57.826086956521735%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABnklEQVQoz53Qy27aQBQG4KEgNkTN47BLJUBIzTOyQUhcFo4aKRteIKoKjqNS7KhoSuIW6o7BxvYYz3gupwqklChVFv10FqMZ/fqPBikNWmsA4FlKKWWM5Tuc50KI3eHJ/gmOoFqtdvbu7P35+fT2EyG+v1onlMZJkiR0s9nEcbzdbtM0jeMYAIbDYbVabTabjUajXq+jQqGAEKpUKqPRiNMoWv+SPJUZZVvqExKGIWMZYyxNUwDodrsIoVKphPaKxSJC6PT07fjGsrB/7SxM7E+WqRtkWikppdhhjAFAv99HCJXL5Tc7T80nJ5Xbz1+cn/TjV8/8thrNA3fNHqN/cM4P4b/Nh7Vte6oEj4MVjQIaBVqK47+RUr4Wnto2AAiplNaPo/QxIcTrzfah4aX9/WAw+HfYcRwAUEr9T9g0zTzPoyjinOcvZFkGAL1e71m41Wq1223DMDDGhJDlcun7/uoIIQRjfL8zHo87nY5hXFxdXF1+uESz2Qxj7HneZDKxLOvuzlksFj+e+f5w/+C67nw+9zxPa01peBNaCUt+A+Yq7xDEkjBFAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/01d59eb54ac16322c3814abf4a9d9148/e4706/Kerberos_VLC_02.avif 230w", "/en/static/01d59eb54ac16322c3814abf4a9d9148/d1af7/Kerberos_VLC_02.avif 460w", "/en/static/01d59eb54ac16322c3814abf4a9d9148/36684/Kerberos_VLC_02.avif 870w"],
              "sizes": "(max-width: 870px) 100vw, 870px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/01d59eb54ac16322c3814abf4a9d9148/a0b58/Kerberos_VLC_02.webp 230w", "/en/static/01d59eb54ac16322c3814abf4a9d9148/bc10c/Kerberos_VLC_02.webp 460w", "/en/static/01d59eb54ac16322c3814abf4a9d9148/bf818/Kerberos_VLC_02.webp 870w"],
              "sizes": "(max-width: 870px) 100vw, 870px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/01d59eb54ac16322c3814abf4a9d9148/81c8e/Kerberos_VLC_02.png 230w", "/en/static/01d59eb54ac16322c3814abf4a9d9148/08a84/Kerberos_VLC_02.png 460w", "/en/static/01d59eb54ac16322c3814abf4a9d9148/3f3b9/Kerberos_VLC_02.png 870w"],
              "sizes": "(max-width: 870px) 100vw, 870px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/01d59eb54ac16322c3814abf4a9d9148/3f3b9/Kerberos_VLC_02.png",
              "alt": "Kerberos & INSTAR IP Cameras",
              "title": "Kerberos & INSTAR IP Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`After you have pressed `}<strong parentName="p">{`Play`}</strong>{`, the MJPEG stream will be played from Kerberos:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "850px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c802dc0f0cd349e405321d88ef434a52/ae694/Kerberos_VLC_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "71.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7DAAAOwwHHb6hkAAADYUlEQVQozwFWA6n8AOTe2t/f4OPj4+fn5+bm5uPj4+Tj4+/v7/Hx8fX19Pf39vf39vf39vb29fb29fb29fb29fLy8u3t7e7u7gCEioyDiop8gX1saWSIiYaZnpmWm5aRlY+GiYKPkoiTlouSloySlYqNkIWOkYaNj4SKioB/gHeCg3uLjIkAZ2ppd3+Blp6ffIKDfn+CrLW6p7C1q7W9oaqug4d+h4yDg4h/goZ8gIR7goV6gIJ3fX50entwgYF5iIiFAGhoZWNmaNLc48vY4rOwtcTJ0cjT39Tf7MbQ1o6SjI6UjYmOiIiMhYiLg4qNhYyPh4qNhYmKhIqMh4+PjgBhYV5naGrX4ezZ4+3s6up6eod3gJDZ5PHBys+QlY+Vm5aOk46Nko2RlI2MjoWFhHmDgXh6dmyBf3iPj44AXVxYaGhpx87X2ubz+fn6fn6Ne4SX5fD4zdDPj5OPfIJ/XWJekZeShYuEhYFxbVY6a1M7aE81VkYzcHJrAGdoZFRWVGZoaa+4x/n7/mxteoOOo+z4/77Dw1VaWVRbWDpAPVxhXW90bIyIeVNHOFJJOk9FODAqIXR3cgBdX1t1eXKOkouMk5rK2Nxub3yVpLzq9/+1v8JLUFFwdnJ+h4U2PDtLTkWAhHkoMTEuNjQgKCYfJSNyb2cAfIB5YGRdT1FKXl9XvsXEaWp3k6G66/r/tsPMeHx8iZCOgX9vXVlFZWhaeoB4R1NUUFRUcnh3VVpXTE1CAISJgDE0Lw4PDzI1MYaIf19dYoSPnau4yJyotHF1dGNkXnJdPG1XNmhkVIyRiZ6loJKZmKGppp+mpHh7dgCHjYRWWlAnKSVESD+Chnd9fnOQlpOUm52DiYROUU1NTUV8Z0dzX0JpX0mPkYeKkYyTl5CVmJRqbGptb28AgImBS1FHExUPKCoif4JxampXcnNqp6+0k5mYam1iaWlZZVA2b1g5YVM4b3JqkJmamaCdnqakWV9bHSEZAMW+u6ekoZmbmqiop8LDvru6s7+/u9DT18/S1NDQy8zLxL22r722rr+6srW3tcbIyM3Qz9DU1MzKzq+trgDd1dXX09Pe39/r6+vm5ufk5OXp6ery8fDx8PDx8fLy8vP19vf19vf09fb19fXy8fHy8vHw7e3Z59jj7+MqM+caezyXdgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c802dc0f0cd349e405321d88ef434a52/e4706/Kerberos_VLC_03.avif 230w", "/en/static/c802dc0f0cd349e405321d88ef434a52/d1af7/Kerberos_VLC_03.avif 460w", "/en/static/c802dc0f0cd349e405321d88ef434a52/4c41f/Kerberos_VLC_03.avif 850w"],
              "sizes": "(max-width: 850px) 100vw, 850px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c802dc0f0cd349e405321d88ef434a52/a0b58/Kerberos_VLC_03.webp 230w", "/en/static/c802dc0f0cd349e405321d88ef434a52/bc10c/Kerberos_VLC_03.webp 460w", "/en/static/c802dc0f0cd349e405321d88ef434a52/10237/Kerberos_VLC_03.webp 850w"],
              "sizes": "(max-width: 850px) 100vw, 850px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c802dc0f0cd349e405321d88ef434a52/81c8e/Kerberos_VLC_03.png 230w", "/en/static/c802dc0f0cd349e405321d88ef434a52/08a84/Kerberos_VLC_03.png 460w", "/en/static/c802dc0f0cd349e405321d88ef434a52/ae694/Kerberos_VLC_03.png 850w"],
              "sizes": "(max-width: 850px) 100vw, 850px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c802dc0f0cd349e405321d88ef434a52/ae694/Kerberos_VLC_03.png",
              "alt": "Kerberos & INSTAR IP Cameras",
              "title": "Kerberos & INSTAR IP Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      